@import "../theme";

.calculator {
    display: flex;
    flex-direction: column;

    @media (min-width: 996px) {
        flex-direction: row;
    }

    .results {
        display: flex;
        flex-direction: column;
        background-color: $primary-color;
        margin-top: 1rem;

        .result {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            height: 100%;
            width: 100%;
            padding: 10px 15px;

            color: $light-color;

            &.active {
                background-color: $secondary-color;
            }
        }

        @media (min-width: 996px) {
            margin-left: 1rem;
            margin-top: 0;
        }
    }

    .step {
        background-color: #f0f1f3;
        display: flex;
        align-items: center;
        padding: 20px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0px;
        }

        input {
            outline: none;
            border: none;
            padding: 0;
            background: none;
            min-height: auto;
            width: 60px;
        }
    }
}
