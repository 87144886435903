@import "../theme";

.card-component {
    box-shadow: 0 0 9px 0px $light-color-1;
    max-width: 340px;
    height: 100%;
    padding: 20px 15px;
    background-color: $light-color;
    width: 100%;
    margin: 0px 10px;
}
