@import "../theme";

.comment {
    padding: 15px;
    margin: 20px;
    background-color: $light-color;
    border-radius: 20px;
    max-width: 500px;
    gap: 30px;
    width: 100%;

    .comment-header {
        display: flex;

        .logo {
            width: 70px;
            height: 70px;
            margin-right: 10px;
            overflow: hidden;

            img {
                object-fit: contain;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }

        .name {
            font-weight: 700;
            margin-bottom: 0.5rem;
        }

        .position {
            opacity: 0.7;
        }
    }
}

.comments {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
