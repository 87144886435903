@import "./theme";

$link-item-font-size: 0.8rem;
$link-item-font-weight: 600;

$nav-active-background: $light-color;

@mixin toggle-logo($logo) {
    .light-logo {
        display: if($logo==light, block, none);
    }

    .dark-logo {
        display: if($logo==dark, block, none);
    }
}

@mixin toggle-nav($type) {
    .default {
        display: if($type==default, flex, none);
    }

    .mobile {
        display: if($type==mobile, block, none);
    }
}

@mixin inverse-light-button() {
    .btn.btn-outline-light {
        border-color: $dark-color;
        color: $dark-color;

        &:hover {
            background-color: $dark-color;
            color: $light-color;
        }
    }
}

#nav {
    width: 100%;
    background-color: $nav-active-background;
    transition: background-color 0.2s ease-in;
    z-index: 999;
    position: fixed;

    @include toggle-logo(dark);

    .link-item {
        padding: 10px 0px;
        text-transform: uppercase;
        font-weight: $link-item-font-weight;

        &.dropdown {
            @media (min-width: 1040px) {
                .dropdown-menu {
                    border: none;
                }
            }

            .link-item {
                margin-bottom: 5px;
                padding-left: 10px;
                a:not(.btn):hover {
                    color: $primary-color;
                }
            }

            &:hover {
                & > .dropdown-menu {
                    display: block;
                }
            }
        }

        @media (min-width: 1040px) {
            padding: 0px 10px;
        }
    }

    .nav-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;

        @media (min-width: 996px) {
            height: 90px;
        }
    }

    .header .logo {
        width: 120px;
    }

    .links {
        & > ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        @include toggle-nav(mobile);

        @media (min-width: 1040px) {
            @include toggle-nav(default);
        }
    }

    @media (max-width: 1039.99px) {
        @include inverse-light-button();
    }

    @media (min-width: 1040px) {
        background-color: rgba(255, 255, 255, 0.2);
        color: $light-color;

        &:not(.active) {
            .link-item a:not(.btn):hover {
                color: $light-color;
            }

            @include toggle-logo(light);
        }

        &.active {
            background-color: $light-color;
            color: $dark-color;

            @include toggle-logo(dark);

            .link-item a:not(.btn):hover {
                color: $primary-color;
            }

            @include inverse-light-button();
        }
    }
}

#mobile-nav {
    @media (min-width: 1040px) {
        display: none;
    }
}
