@import "./theme";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

@import "./components/collection";
@import "./components/tab";
@import "./components/switch";
@import "./components/card-columns";
@import "./components/calculator";
@import "./components/card";
@import "./components/slideshow";
@import "./components/comments";
@import "./components/dot-list";
@import "./components/blog";

@import "./nav";
@import "./banner";
@import "./start-for-free";
@import "./applications";
@import "./contact";
@import "./footer";

body {
    color: $dark-color;
}

h1,
h2,
h3 {
    font-weight: 700;
}

@media (max-width: 768px) {
    .display-1 {
        font-size: 1.7rem;
    }
}

p {
    line-height: 1.9rem;
    font-weight: 400;
    font-size: 1rem;
}

table {
    font-weight: 500;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0px;
}

a:not(.btn) {
    font-size: 1rem;
    text-decoration: none;
    color: unset;

    &:hover {
        color: $primary-color;
    }
}

section,
footer {
    padding-top: 20px;
    padding-bottom: 25px;

    @media (min-width: 996px) {
        padding-top: 80px;
        padding-bottom: 100px;
    }
}

.dark-section {
    background-color: $light-color-2;
}

table {
    overflow: auto;
}

.borderless,
.borderless td,
.borderless th {
    border: none;
}

.light-color {
    color: $light-color;
}

.btn-primary,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:visited {
    color: $light-color;
}

input.form-control,
textarea.form-control {
    box-shadow: none;
    border-radius: inherit;
    border: none;
    border-bottom: 2px solid;
    border-color: $light-color-2 !important;
    border-radius: 5px;
    padding: 12px 12px;

    &:focus {
        box-shadow: none;
        border-bottom: none;
    }
}
