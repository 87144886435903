@import "../theme";

.tab {
    .tab-navigation {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        background-color: $primary-color;
        overflow: hidden;
        border-radius: 50px;
        margin-bottom: 30px;
        overflow: auto;

        @media (min-width: 768px) {
            justify-content: center;
            margin-bottom: 100px;
        }

        & > * {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 15px;
            color: $light-color;
            width: 100%;
            text-align: center;
            cursor: pointer;
            transition: background-color 0.35s ease-in-out;
            white-space: nowrap;

            &.active,
            &:hover {
                background-color: $secondary-color;
            }
        }
    }

    .tab-container {
        & > * {
            display: none;
            opacity: 0;
            flex-direction: column;

            &.active {
                display: flex;
                animation: fadeIn 0.35s 0.1s linear 1 forwards;
            }

            & > * {
                width: 100%;
                text-align: center;

                @media (min-width: 768px) {
                    width: 50%;
                    text-align: left;
                }
            }

            @media (min-width: 768px) {
                flex-direction: row;
            }

            .tab-content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-right: 50px;

                p {
                    max-width: 400px;
                }
            }

            .tab-preview img {
                width: 100%;
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
