@import "../theme";

.switch {
    background-color: $primary-color;
    border-radius: 50px;
    padding: 5px;
    margin: 0 auto;
    display: inline-flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    color: $light-color;
    margin: 20px 0px;

    & > div {
        padding: 8px 20px;
        border-radius: 50px;

        &.active {
            background-color: $light-color;
            color: $dark-color;
        }
    }
}
