@import "../theme";

.dot-list {
    margin-left: 5rem;

    li {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: -20px;
            top: 5px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: $primary-color;
        }
    }
}
