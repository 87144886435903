.card-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > .column {
        width: 340px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        text-align: center;
        box-shadow: 0 0 9px 0px $light-color-1;
        margin: 10px;
    }
}
