@import "../theme";

.blog-post {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    margin-bottom: 5rem;
    padding: 0px;
    position: relative;

    @media (min-width: 768px) {
        flex-direction: row;
    }

    .blog-date {
        position: absolute;
        top: 10px;
        left: -10px;
        color: $light-color;
        background-color: $dark-color;
        padding: 5px;
    }

    .blog-content {
        padding: 10px 15px 10px;

        @media (min-width: 768px) {
            padding: 35px 45px 20px;
        }
    }

    .blog-cover {
        width: 100%;
        height: 100%;
        border-radius: 7px 7px 0px 0px;
        overflow: hidden;

        @media (min-width: 768px) {
            max-width: 370px;
            border-radius: 7px 0px 0px 7px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.blog {
    .blog-date {
        background-color: $primary-color;
        color: $light-color;
        padding: 5px 10px;
    }

    .blog-content {
        ul {
            margin-left: 2rem;
            list-style-type: disc;
        }
    }
}
