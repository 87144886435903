@import "./theme";

#banner {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    overflow: hidden;

    &.large {
        height: 400px;
    }

    @media (min-width: 768px) {
        height: 460px;

        &.large {
            height: 750px;
        }
    }

    .content {
        position: relative;
        z-index: 2;

        .banner-content {
            max-width: 700px;
            margin: 0 auto;
            margin-top: 20px;
            font-weight: 400;
            font-size: 1rem;

            @media (min-width: 768px) {
                margin-top: 50px;
                font-size: $h4-font-size;
            }
        }
    }

    &::after,
    &::before {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    &::before {
        background: #5e5e5e none repeat scroll 0 0;
        opacity: 0.6;
    }

    &::after {
        background-image: url("../assets/images/waves.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 calc(100% + 2px);
        z-index: 1;
        width: calc(100% + 1px);
    }
    &.gray-waves::after {
        background-image: url("../assets/images/waves-gray.png");
    }
}
