@import "../theme.scss";
@import "~@splidejs/splide/dist/css/splide.min.css";

.splide__list {
    margin: 40px 20px !important;
}

.splide__pagination__page {
    background-color: #dedede;
    width: 10px;
    height: 8px;
    border-radius: 30px;
    transition: width 0.15s ease-in-out;

    &.is-active {
        background-color: $primary-color;
        opacity: 1;
        transform: none;
        width: 40px;
    }
}
