.collection {
    & > ul {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: none;
        margin-right: 50px;

        @media (min-width: 1200px) {
            max-height: 350px;
        }

        & > li {
            margin-bottom: 30px;
            margin-right: 50px;
            display: flex;
            align-items: center;
            & > :first-child {
                margin-right: 10px;
            }
        }
    }
}

.collections {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (min-width: 996px) {
        justify-content: center;
    }
}
