$light-color: #fff;
$dark-color: #5d5d5d;
$light-color-2: #f7f7f7;
$light-color-1: #e8e7e7;
$primary-color: #5bb4e4;
$secondary-color: #4373b9;
$danger-color: #f85f6b;
$success-color: #37a7a4;

$font-size-root: 14px;
$font-family-sans-serif: "Poppins", sans-serif;
$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$display-font-sizes: (
    1: 3.7rem,
    2: 4.5rem,
    3: 4rem,
    4: 3.5rem,
    5: 3rem,
    6: 2.5rem,
);

$display-font-weight: 600;
$headings-font-weight: 700;

$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "danger": $danger-color,
    "light": $light-color,
    "dark": $dark-color,
    "success": $success-color,
);

$table-striped-color: $dark-color;
$table-color: $dark-color;

$btn-font-size: 0.8rem;
$btn-border-radius: 30px;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1.2rem;
$btn-font-weight: 600;
